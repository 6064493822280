import { Action, generateStoreContext } from './GenerateContext';

import { LoadState } from '../common/LoadState';
import { AppActionType } from './AppActionType';
import { Features, initialFeatures } from './FeaturesState';

const initialState = {
  readyState: LoadState.Idle,
  initialSearchParams: undefined as URLSearchParams,
  showFooter: true,
  showFooterMintDates: false,
  showFooterDiscordOnly: false,
  mainConFullHeight: false,
  showWalletButton: false,
  showNavbar: true,
  fixedNavbar: false,
  features: initialFeatures,
  showSignUpModal: false,
  signUpEmail: '',
  showSecretMenu: false,
  //
  showGame: false,
  showOnboard: true,
  isCreate: false,
  isAiForm: false,
  navbarEnabled: true,
};

export type AppState = typeof initialState;
export type FeaturesActionProps = Partial<Features>;
export type AppActionProps =
  | {
      type: AppActionType.UpdateReadyState;
      readyState: LoadState;
    }
  | {
      type: AppActionType.UpdateSearchParams;
      searchParams: string | URLSearchParams | string[][] | Record<string, string>;
    }
  | {
      type: AppActionType.UpdateFooter;
      showFooter?: boolean;
      showFooterMintDates?: boolean;
      showFooterDiscordOnly?: boolean;
    }
  | {
      type: AppActionType.UpdateMainCon;
      fullHeight: boolean;
    }
  | {
      type: AppActionType.UpdateWalletButton;
      showWalletButton: boolean;
    }
  | {
      type: AppActionType.UpdateNavbar;
      showNavbar?: boolean;
      fixedNavbar?: boolean;
    }
  | {
      type: AppActionType.UpdateFeatures;
      features: FeaturesActionProps;
    }
  | {
      type: AppActionType.UpdateSignUpEmail;
      email?: string;
      showSignUpModal?: boolean;
    }
  | {
      type: AppActionType.UpdateShowSecretMenu;
      show: boolean;
    }
  | {
      type: AppActionType.GameIsPlaying;
      showGame: boolean;
    }
  | {
      type: AppActionType.UpdateShowOnboard;
      showOnboard: boolean;
    }
  | {
      type: AppActionType.UpdateIsCreate;
      isCreate: boolean;
    }
  | {
      type: AppActionType.UpdateIsAiForm;
      isAiForm: boolean;
    }
  | {
      type: AppActionType.ToggleEnableNavbar;
      navbarEnabled: boolean;
    };

function reducer(state: AppState, action: Action<AppActionType, AppActionProps>): AppState {
  let features: Features;
  switch (action.type) {
    case AppActionType.UpdateReadyState:
      if (!action.readyState) {
        throw new Error(`app-context: Missing "readyState" property for "${action.type}" action`);
      }
      return {
        ...state,
        readyState: action.readyState,
      };
    case AppActionType.UpdateSearchParams:
      if (state.initialSearchParams) {
        return state;
      }
      return {
        ...state,
        initialSearchParams: new URLSearchParams(action.searchParams),
      };
    case AppActionType.UpdateFooter:
      return {
        ...state,
        showFooter: action.showFooter ?? state.showFooter,
        showFooterMintDates: action.showFooterMintDates ?? state.showFooterMintDates,
        showFooterDiscordOnly: action.showFooterDiscordOnly ?? state.showFooterDiscordOnly,
      };
    case AppActionType.UpdateMainCon:
      return {
        ...state,
        mainConFullHeight: action.fullHeight ?? state.mainConFullHeight,
      };
    case AppActionType.UpdateWalletButton:
      return {
        ...state,
        showWalletButton: action.showWalletButton,
      };
    case AppActionType.UpdateNavbar:
      return {
        ...state,
        showNavbar: action.showNavbar ?? state.showNavbar,
        fixedNavbar: action.fixedNavbar ?? state.fixedNavbar,
      };
    case AppActionType.UpdateFeatures:
      features = {
        ...state.features,
        ...action.features,
      };
      return {
        ...state,
        features,
      };
    case AppActionType.UpdateSignUpEmail:
      return {
        ...state,
        signUpEmail: action.email ?? state.signUpEmail,
        showSignUpModal: action.showSignUpModal ?? state.showSignUpModal,
      };
    case AppActionType.UpdateShowSecretMenu:
      return {
        ...state,
        showSecretMenu: action.show,
      };
    case AppActionType.GameIsPlaying:
      return {
        ...state,
        showGame: action.showGame,
      };
    case AppActionType.UpdateShowOnboard:
      return {
        ...state,
        showOnboard: action.showOnboard,
      };
    case AppActionType.UpdateIsCreate:
      return {
        ...state,
        isCreate: action.isCreate,
      };
    case AppActionType.UpdateIsAiForm:
      return {
        ...state,
        isAiForm: action.isAiForm,
      };
    case AppActionType.ToggleEnableNavbar:
      return {
        ...state,
        navbarEnabled: action.navbarEnabled,
      };
    default:
      throw new Error(`app-context: Unknown action type "${(action as any).type}"`);
  }
}

const { StateContext, DispatchContext, ContextProvider, ContextConsumer, withContext, useContextState, useContextDispatch } = generateStoreContext<
  AppState,
  AppActionType,
  AppActionProps
>(reducer, initialState, 'appState', 'appDispatch');

export {
  StateContext as AppStateContext,
  DispatchContext as AppDispatchContext,
  ContextProvider as AppProvider,
  ContextConsumer as AppConsumer,
  withContext as withApp,
  useContextState as useAppState,
  useContextDispatch as useAppDispatch,
  AppActionType,
};
