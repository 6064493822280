export enum AppActionType {
  UpdateReadyState = 'app/updateReadyState',
  UpdateSearchParams = 'app/updateSearchParams',
  UpdateFooter = 'app/updateFooter',
  UpdateMainCon = 'app/updateMainCon',
  UpdateWalletButton = 'app/updateWalletButton',
  UpdateNavbar = 'app/updateNavbar',
  UpdateFeatures = 'app/updateFeatures',
  UpdateSignUpEmail = 'app/updateSignUpEmail',
  UpdateShowSecretMenu = 'app/updateShowSecretMenu',
  GameIsPlaying = 'app/gameIsPlaying',
  UpdateShowOnboard = 'app/UpdateShowOnboard',
  UpdateIsCreate = 'app/UpdateIsCreate',
  UpdateIsAiForm = 'app/UpdateIsAiForm',
  ToggleEnableNavbar = 'app/ToggleEnableNavbar',
}
