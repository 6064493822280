import './TopBar.scss';

import { AppActionType, useAppDispatch, useAppState } from '@context/AppContext';
import IconEthereum from '@assets/_gemz/icons8-ethereum-100.png';
import IconSearch from '@assets/_gemz/icons8-search-100.png';

export type TopBarProps = {};

export function TopBar({}: TopBarProps) {
  const appState = useAppState();
  const appDispatch = useAppDispatch();

  return (
    <div className="top-bar">
      <div className="navbar-items">
        <div className="navbar-item ethereum">
          <div className="navbar-item-icon" style={{ backgroundImage: `url(${IconEthereum})` }} />
          <div className="navbar-item-label">0.015 ETH</div>
        </div>

        <div className="navbar-item search">
          <div className="navbar-item-icon" style={{ backgroundImage: `url(${IconSearch})` }} />
          <div className="navbar-item-label"></div>
        </div>
      </div>
    </div>
  );
}

export default TopBar;
