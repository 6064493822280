import './BottomCreateBar.scss';

import IconHome from '@assets/_gemz/icons8-home-100.png';

import { AppActionType, useAppDispatch, useAppState } from '@context/AppContext';
import { useNavigate } from 'react-router-dom';
import { useGemzState } from '@context/gemz/GemzContext';
import { mainSuite } from '@services/ServiceFactory';
import { useEffect, useState } from 'react';

export type BottomCreateBarProps = {};

export function BottomCreateBar({}: BottomCreateBarProps) {
  const appState = useAppState();
  const appDispatch = useAppDispatch();
  const gemzState = useGemzState();

  const navigate = useNavigate();

  const [aiProgress, setAiProgress] = useState(0);
  const [aiComplete, setAiComplete] = useState(false);

  // Add viewer callback
  useEffect(() => {
    const onUpdateAiProgress = (progress: number) => {
      console.warn('>>> onUpdateAiProgress', progress);

      setAiProgress(progress);
    };

    const onCompleteAiProgress = () => {
      console.warn('>>> onCompleteAiProgress');
      setAiComplete(true);
    };

    mainSuite.navbarService.on('UPDATE_AI_PROGRESS', onUpdateAiProgress);
    mainSuite.navbarService.on('GENERATE_AI_STORY_COMPLETE', onCompleteAiProgress);

    return () => {
      mainSuite.navbarService.off('UPDATE_AI_PROGRESS', onUpdateAiProgress);
      mainSuite.navbarService.off('GENERATE_AI_STORY_COMPLETE', onCompleteAiProgress);
    };
  }, []);

  const abort = async () => {
    appDispatch({
      type: AppActionType.UpdateIsCreate,
      isCreate: false,
    });

    appDispatch({
      type: AppActionType.UpdateIsAiForm,
      isAiForm: false,
    });

    appDispatch({
      type: AppActionType.ToggleEnableNavbar,
      navbarEnabled: true,
    });

    appDispatch({
      type: AppActionType.GameIsPlaying,
      showGame: false,
    });

    navigate('/');
  };

  return (
    <div className="bottom-navbar-create">
      <div className="navbar-items">
        <div className="navbar-item abort" onClick={async () => abort()}>
          <div className="navbar-item-icon" style={{ backgroundImage: `url(${IconHome})` }} />
          <div className="navbar-item-label">Exit</div>
        </div>

        <div className="navbar-separator" />

        <div className="navbar-item ai-progress">
          <div className="progress-title">
            {aiComplete ? `Your story is ready` : `Generating your AI story`}
            <div className="progress-subtitle">
              {!aiComplete ? `(You can still play while the story is being generated)` : `Publish it by solving the correct choices and buying the story Gem!`}
            </div>
          </div>
          {!aiComplete && <div className="progress-bar" style={{ width: aiProgress * 2.6 }} />}
        </div>
      </div>
    </div>
  );
}

export default BottomCreateBar;
