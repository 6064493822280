import './ProfilePage.scss';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppActionType, useAppDispatch } from '@context/AppContext';
import { TransitioningSection } from '@components/transitioning-section';

import IconBack from '@assets/_gemz/profile/icons8-back-100.png';
import IconFavourite from '@assets/_gemz/profile/icons8-add-to-favorites-100.png';
import IconNotification from '@assets/_gemz/profile/icons8-notification-96.png';
import IconShare from '@assets/_gemz/profile/icons8-share-100.png';

interface Feed {
  id: string;
  created_at: string;
  creator_address: string;
  story_id: string;
  owner_address: string;
  tx_hash: string;
  confirmed: boolean;
  username: string;
  gem_name: string;
  gem_image: string;
  content_url: string;
  profile_image_url?: string;
}

const fetchFeed = async () => {
  const response = await fetch('https://pipeline.beta.pnk.one/gems/feed/activity');
  return response.json();
};

export const ProfilePage = () => {
  const navigate = useNavigate();
  const appDispatch = useAppDispatch();

  useEffect(() => {
    appDispatch({
      type: AppActionType.UpdateShowOnboard,
      showOnboard: false,
    });
  }, []);

  const [userFeed, setUserFeed] = useState<Feed[]>([]);

  const updateUserFeed = async () => {
    const nextFeed = await fetchFeed();

    if (nextFeed.error) {
      return;
    }

    if (JSON.stringify(nextFeed) !== JSON.stringify(userFeed)) {
      setUserFeed(nextFeed);
    }
  };

  console.warn('>>> ProfilePage');
  let loaded = userFeed.length > 0; // notifications.length > 0;

  // todo: here we need to retrieve info about the user itself, instead of this nonsense
  // todo: userName, userTwitterHandle, userTwitterPageUrl, userTwitterProfilePic
  // todo: the feed items will need to render special thumbnails of first steps, generated by server-side/jb
  const username = loaded ? userFeed[0]?.username : '';
  const userTwitterHandle = loaded ? `@${userFeed[0]?.username.toString().replace(/\s/g, '')}` : '';
  const userProfilePic = loaded ? userFeed[0]?.profile_image_url : '';
  const userTwitterUrl = 'http://twitter.com';

  // todo: then we need to filter the feed by games owned by the user only.
  updateUserFeed();

  return (
    <div className="profile-page">
      <div className="profile-topbar">
        <div className="profile-topbar-item back" style={{ backgroundImage: `url(${IconBack})` }} onClick={() => navigate(`/`)} />
        <div className="profile-topbar-item favourite" style={{ backgroundImage: `url(${IconFavourite})` }} />
        <div className="profile-topbar-item notification" style={{ backgroundImage: `url(${IconNotification})` }} />
        <div className="profile-topbar-item share" style={{ backgroundImage: `url(${IconShare})` }} />
      </div>

      <div className="profile-scrollable">
        <div className="profile-user">
          <div className="name">{username}</div>
          <div className="image" style={{ backgroundImage: `url(${userProfilePic})` }} />
          <div className="handle" onClick={() => (window.location.href = userTwitterUrl)}>
            {userTwitterHandle}
          </div>
          <div className="info">
            <div className="pinfo-item holders">{`135\nholders`}</div>
            <div className="pinfo-item holding">{`354\nholding`}</div>
            <div className="pinfo-item gemz">{`${userFeed.length}\ngemz`}</div>
            <div className="pinfo-item value">{`3.572\ntotal value`}</div>
          </div>
        </div>

        <div className={`profile-feed ${loaded ? '' : 'loading'}`}>
          <div className="profile-feed-title">Games</div>
          <div className="profile-feed-items">
            {userFeed.map((n, index) => (
              <div className="profile-feed-item" key={n.id} style={{ backgroundImage: `url(${n.gem_image})` }}>
                <div className="image"></div>
                <div className="info">
                  <div className="info-item gem">{n.gem_name}</div>
                  <div className="info-item value">0.01 ETH</div>
                  <div className="info-item holders"> 100 Holders</div>
                </div>
              </div>
            ))}
          </div>

          {!loaded && <TransitioningSection fixed={false} />}
        </div>
      </div>
    </div>
  );
};

// ------------------------------------------------------------------------------

interface PProps {
  img: string;
  isGem?: boolean;
}

const Picture = ({ img, isGem }: PProps) => {
  console.log('');
  return (
    <div className={`round-picture ${!isGem && 'right-above'}`}>
      <img id="pic-img" src={img} />
    </div>
  );
};
