export interface GemzItem {
  id: string;
  created_at: string;
  username: string;
  gemname: string;
  creator_address: string;
  story_index: string;
  story_image?: string;
  content_url: string;
  profile_url: string;
  profile_image_url: string;
  gemimage: string;
}

export const getPagePath = (gemname: string, slash = '/') => `${slash}${gemname.replace(/ /g, '_').toLocaleLowerCase().trim()}`;
