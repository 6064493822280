import { GemzItem, getPagePath } from '@common/GemzItem';
import { Action, generateStoreContext } from '../GenerateContext';
import { GemzActionType } from './GemzActionType';
import { LoadState } from '@common/LoadState';

export type GemzState = {
  feed: GemzItem[];
  feedState: LoadState;
  errorMessage: string | null | undefined;
};

export type GemzBringGemToTopAction = {
  type: GemzActionType.BringGemToTop;
  gemName?: string;
  gemPath?: string;
  gemId?: string;
};

export type GemzActionProps =
  | {
      type: GemzActionType.UpdateFeed;
      feed: GemzItem[];
      feedState?: LoadState;
    }
  | GemzBringGemToTopAction
  | {
      type: GemzActionType.UpdateFeedState;
      feedState: LoadState;
      errorMessage?: string;
    };

const initialState: GemzState = {
  feed: [],
  feedState: LoadState.Idle,
  errorMessage: null,
};

function createFindGemByAction(action: GemzBringGemToTopAction) {
  if (action.gemId) {
    return (item: GemzItem) => item.id === action.gemId;
  }
  if (action.gemName) {
    return (item: GemzItem) => item.gemname.toLowerCase() === action.gemName.toLowerCase();
  }
  if (action.gemPath) {
    return (item: GemzItem) => getPagePath(item.gemname) === action.gemPath;
  }

  throw new Error('createFindGemByAction: missing gemId, gemPath, or gemId');
}

function reducer(state: GemzState, action: Action<GemzActionType, GemzActionProps>) {
  let item: GemzItem;
  let feed: GemzItem[];
  let index: number;
  switch (action.type) {
    case GemzActionType.UpdateFeed:
      return {
        ...state,
        feed: action.feed,
        feedState: action.feedState ?? LoadState.Loaded,
      };
    case GemzActionType.BringGemToTop:
      if (!action.gemId && !action.gemPath && !action.gemId) {
        throw new Error(`GemzContext: missing gemId, gemPath, or gemId for action ${action.type}`);
      }

      feed = [...state.feed];
      index = feed.findIndex(createFindGemByAction(action));
      if (index === -1) {
        return state;
      }

      item = feed[index];
      feed.splice(index, 1);
      feed.unshift(item);

      return {
        ...state,
        feed,
      };
    case GemzActionType.UpdateFeedState:
      return {
        ...state,
        feedState: action.feedState,
        errorMessage: action.errorMessage ?? state.errorMessage,
      };
    default:
      throw new Error(`GemzContext: Unknown gemz action type: ${(action as any).type}`);
  }
}

const { StateContext, DispatchContext, ContextProvider, ContextConsumer, withContext, useContextState, useContextDispatch } = generateStoreContext<
  GemzState,
  GemzActionType,
  GemzActionProps
>(reducer, initialState, 'mintState', 'mintDispatch');

export {
  StateContext as GemzStateContext,
  DispatchContext as GemzDispatchContext,
  ContextProvider as GemzProvider,
  ContextConsumer as GemzConsumer,
  withContext as withGemz,
  useContextState as useGemzState,
  useContextDispatch as useGemzDispatch,
  GemzActionType,
};
